import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
import VideoRundenProzess from "../../assets/RundenProzess-v6-web.mp4"


// import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  //description,
  row,
  //col6,
  col12,
  // marginTopSubstitute,
  //hubSpotForm,
} from "../subpage.module.scss";

const GreetingsPage = () => {
  return (
    <Layout pageTitle="GutenRutsch" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>

                <h1>Guetä Rutsch :)</h1>
              
                <video controls>
                  <source src={VideoRundenProzess} type="video/mp4"/>
                  <track src="captions_en.vtt" kind="captions" srclang="de" label="md-akte-deutsch"></track>
                </video>

                </div>

              
            </div>



           
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default GreetingsPage;
